<script lang="ts">
	import TopCard from '$lib/features/top/components/TopCard.svelte';
	import TopMessage from '$lib/features/top/components/TopMessage.svelte';
	import { css } from 'styled-system/css';
	import type { PageData } from './$types';
	import type { Cmp } from '$lib/features/shared/types/component';
	import { grid } from 'styled-system/patterns';

	export const baseUrl = 'https://hmnkpl.com';
	export let data: PageData;

	$: cards = data.cards.map((it) => {
		return {
			title: it.title,
			/* eslint-disable @typescript-eslint/no-unsafe-assignment*/
			cmp: it.component as unknown as Cmp,
		};
	});
</script>

<svelte:head>
	<title>Home | Haruhito Aso</title>
	<link rel="icon" href={`${baseUrl}/favicon.png`} />
	<meta name="description" content="Haruhito Aso backend engineer" />

	<meta property="og:title" content="Home | Haruhito Aso" />
	<meta property="og:description" content="Haruhito Aso" />
	<meta property="og:url" content={baseUrl} />
	<meta property="og:image" content={`${baseUrl}/images/ogp/top.png`} />
	<meta property="og:type" content="website" />
	<meta property="og:image:width" content="800" />
	<meta property="og:image:height" content="600" />
</svelte:head>

<section
	class={grid({
		md: {
			gridTemplateColumns: '2fr 3fr',
			alignItems: 'start',
		},
	})}
>
	<div
		class={css({
			paddingBottom: '1rem',
			md: {
				top: '2.6rem',
				position: 'sticky',
				alignSelf: 'start',
			},
		})}
	>
		<TopMessage />
	</div>
	<div class={css({ top: '2.6rem', bottom: '2rem' })}>
		{#each cards as card}
			<TopCard title={card.title} cmp={card.cmp} />
		{/each}
	</div>
</section>
